import React from "react";
// import Slider from "react-slick";
import "./carousal.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faChevronLeft,
//   faChevronRight,
// } from "@fortawesome/free-solid-svg-icons";

// import video1 from "../../assets/ScreenRecording.webm";
// import video2 from "../../assets/ScreenRecording.webm";
// import video3 from "../../assets/ScreenRecording.webm";

// const CustomPrevArrow = ({ onClick }) => (
//   <div onClick={onClick} className="arrow left-arrow">
//     <FontAwesomeIcon icon={faChevronLeft} />
//   </div>
// );

// const CustomNextArrow = ({ onClick }) => (
//   <div onClick={onClick} className="arrow right-arrow">
//     <FontAwesomeIcon icon={faChevronRight} />
//   </div>
// );

const SimpleSlider = () => {
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: true,
  //   prevArrow: <CustomPrevArrow />,
  //   nextArrow: <CustomNextArrow />,
  // };

  return (
    <div className="carousal">
      <center>
        <div className="carousal-container" style={{ position: "relative" }}>
          <div className="inside-carousal">
            <p className="topic">OUR FUTURE FIRST SUCCESS STORIES</p>
            <p className="topic-2">
              See how we make a difference through these real-life stories.
            </p>
            <div className="carousal-slider">
              <video
                autoPlay
                controls
                muted
                className="carousal-video"
                style={{ borderRadius: "10px", width: "80%" }}
              >
                <source
                  src="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/videos/Hirdaramani%20Employee%20Feature%20Video.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </center>
    </div>
  );
};

export default SimpleSlider;
