import React from "react";
import Page from "../templates/conserve/chemical/chemicalpage";

export default function home() {
  return (
    <div>
      <Page />
    </div>
  );
}
