import React from "react";
import { Link } from "react-router-dom";
import CompanyLogo from "../assets/logo-white.png";
import "./header.css";
import ScrollArrow from "./scroll-arrow";
import Logo from "./logo-2";

export default function Header() {
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <Link to="/" className="link">
        <img src={CompanyLogo} alt="Company Logo" className="company-logo" />
      </Link>
      <Logo />
      <ScrollArrow />
    </div>
  );
}
