import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./employees-by-gender.css";
import MaleChart from "./male";
import FemaleChart from "./female";

import Man from "../../../../assets/Man.webp";
import Women from "../../../../assets/Women2.webp";

export default function Chart() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <div>
      <h2 className="employees-chart-topic">Employees by Gender</h2>
      <br />

      <center>
        <div className="chart-pc">
          <div className="card-container" style={{ width: "380px" }}>
            <div className="card" style={{ width: "130px" }}>
              <div className="card-content">
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  <div className="chart-img-container">
                    <img src={Man} alt="" className="chart-img" />
                    <div className="card-overlay">
                      <div>
                        <h3 className="number-loader">
                          {counterOn && (
                            <CountUp
                              start={0}
                              end={36}
                              duration={3}
                              delay={0.3}
                            />
                          )}
                          %
                        </h3>
                      </div>
                    </div>
                  </div>
                </ScrollTrigger>
              </div>
            </div>

            <div className="card" style={{ width: "46px" }}>
              <div className="card-content">
                <MaleChart />
              </div>
            </div>

            <div className="card" style={{ width: "46px" }}>
              <div className="card-content">
                <FemaleChart />
              </div>
            </div>

            <div className="card" style={{ width: "130px" }}>
              <div className="card-content">
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  <div className="chart-img-container">
                    <img src={Women} alt="" className="chart-img" />
                    <div className="card-overlay">
                      <div>
                        <h3 className="number-loader">
                          {counterOn && (
                            <CountUp
                              start={0}
                              end={64}
                              duration={3}
                              delay={0.3}
                            />
                          )}
                          %
                        </h3>
                      </div>
                    </div>
                  </div>
                </ScrollTrigger>
              </div>
            </div>
          </div>
        </div>

        {/* //// */}
        <div className="chart-mobile">
          <div className="card-container">
            <div className="card" style={{ width: "130px" }}>
              <div className="card-content">
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  <div className="chart-img-container">
                    <img src={Man} alt="" className="chart-img" />
                    <div className="card-overlay">
                      <div>
                        <h3 className="number-loader">
                          {counterOn && (
                            <CountUp
                              start={0}
                              end={36}
                              duration={3}
                              delay={0.3}
                            />
                          )}
                          %
                        </h3>
                      </div>
                    </div>
                  </div>
                </ScrollTrigger>
              </div>
            </div>

            <div className="card" style={{ width: "46px" }}>
              <div className="card-content">
                <MaleChart />
              </div>
            </div>
          </div>
          <div>
            <div className="card-container">
              <div className="card" style={{ width: "46px" }}>
                <div className="card-content">
                  <FemaleChart />
                </div>
              </div>

              <div className="card" style={{ width: "130px" }}>
                <div className="card-content">
                  <ScrollTrigger
                    onEnter={() => setCounterOn(true)}
                    onExit={() => setCounterOn(false)}
                  >
                    <div className="chart-img-container">
                      <img src={Women} alt="" className="chart-img" />
                      <div className="card-overlay">
                        <div>
                          <h3 className="number-loader">
                            {counterOn && (
                              <CountUp
                                start={0}
                                end={64}
                                duration={3}
                                delay={0.3}
                              />
                            )}
                            %
                          </h3>
                        </div>
                      </div>
                    </div>
                  </ScrollTrigger>
                </div>
              </div>
            </div>
          </div>
        </div>
      </center>
      {/* /// */}
    </div>
  );
}
