import React from 'react'
import Page from '../templates/empower/career-development/spotlight/SpotLight-workplace-training-and-education-programs'

function spotlight() {
  return (
    <div>
      <Page/>
    </div>
  )
}

export default spotlight
