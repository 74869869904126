import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./srilanka.css";

export default function Male() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div className="sri-lanka-box-1 career-development-sri-lanka-box-1">
        {counterOn && <CountUp start={0} end={188} duration={3} delay={0.3} />}
      </div>
      <br />
      <div className="sri-lanka-box-2 career-development-sri-lanka-box-2">
        {counterOn && <CountUp start={0} end={2794} duration={3} delay={0.3} />}
      </div>
      <br />
      <div className="sri-lanka-box-3 career-development-sri-lanka-box-3">
        {counterOn && <CountUp start={0} end={390} duration={3} delay={0.3} />}
      </div>
      <br />
      <div className="sri-lanka-box-4 career-development-sri-lanka-box-4">
        {counterOn && <CountUp start={0} end={38} duration={3} delay={0.3} />}%
      </div>
    </ScrollTrigger>
  );
}
