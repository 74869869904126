import React from "react";
import CompanyLogo from "../assets/logo-white-2.png";
import "./logo-2.css";

export default function Logo() {
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <img src={CompanyLogo} alt="Company Logo" className="company-logo-2" />
    </div>
  );
}
