import React from "react";
import Page from "../templates/support/childrin&education/spotlight/t-e-a-project";

export default function home() {
  return (
    <div>
      <Page />
    </div>
  );
}
