import React from 'react'
import Page from "../templates/conserve/conservepage";


export default function home() {
  return (
    <div>
        <Page />
    </div>
  )
}
