import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./employees-by-gender.css";
import MaleChart from "./male";
import FemaleChart from "./female";

import Man from "../../../../assets/Man.webp";
import Women from "../../../../assets/Women2.webp";

export default function Chart() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <div style={{ position: "relative" }}>
      <h2 className="employees-chart-topic">
        % Return to work after parental leave by gender
      </h2>
      <br />

      <div className="chart-pc">
        <div className="card-container-2">
          <div className="card" style={{ width: "180px" }}>
            <div className="card-content-2">
              <ScrollTrigger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                <div className="chart-img-container-2">
                  <img src={Man} alt="" className="chart-img-2" />
                  <div className="card-overlay-2">
                    <div>
                      <h3 className="number-loader-2">
                        {counterOn && (
                          <CountUp
                            start={0}
                            end={36}
                            duration={3}
                            delay={0.3}
                          />
                        )}
                        %
                      </h3>
                    </div>
                  </div>
                </div>
              </ScrollTrigger>
            </div>
          </div>

          <div className="card" style={{ width: "90px" }}>
            <div className="card-content-2">
              <MaleChart />
            </div>
          </div>

          <div className="card" style={{ width: "85px" }}>
            <div className="card-content-2">
              <FemaleChart />
            </div>
          </div>

          <div className="card" style={{ width: "180px" }}>
            <div className="card-content-2">
              <ScrollTrigger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                <div className="chart-img-container-2">
                  <img src={Women} alt="" className="chart-img-2" />
                  <div className="card-overlay-2">
                    <div>
                      <h3 className="number-loader-2">
                        {counterOn && (
                          <CountUp
                            start={0}
                            end={64}
                            duration={3}
                            delay={0.3}
                          />
                        )}
                        %
                      </h3>
                    </div>
                  </div>
                </div>
              </ScrollTrigger>
            </div>
          </div>
        </div>
      </div>

      {/* //// */}
      <div className="chart-mobile">
        <div className="card-container-2">
          <div className="card" style={{ width: "130px" }}>
            <div className="card-content-2">
              <ScrollTrigger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                <div className="chart-img-container-2">
                  <img src={Man} alt="" className="chart-img-2" />
                  <div className="card-overlay-2">
                    <div>
                      <h3 className="number-loader-2">
                        {counterOn && (
                          <CountUp
                            start={0}
                            end={36}
                            duration={3}
                            delay={0.3}
                          />
                        )}
                        %
                      </h3>
                    </div>
                  </div>
                </div>
              </ScrollTrigger>
            </div>
          </div>

          <div className="card" style={{ width: "46px" }}>
            <div className="card-content-2">
              <MaleChart />
            </div>
          </div>
        </div>
        <div>
          <div className="card-container-2">
            <div className="card" style={{ width: "46px" }}>
              <div className="card-content-2">
                <FemaleChart />
              </div>
            </div>

            <div className="card" style={{ width: "130px" }}>
              <div className="card-content-2">
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  <div className="chart-img-container-2">
                    <img src={Women} alt="" className="chart-img-2" />
                    <div className="card-overlay-2">
                      <div>
                        <h3 className="number-loader-2">
                          {counterOn && (
                            <CountUp
                              start={0}
                              end={64}
                              duration={3}
                              delay={0.3}
                            />
                          )}
                          %
                        </h3>
                      </div>
                    </div>
                  </div>
                </ScrollTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /// */}
    </div>
  );
}
