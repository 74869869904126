import React, { useEffect } from "react";
import "./EmpowerCharts.css";

//Charts
import EmpowerPieChart01 from "./EmpowerPieChart01";
import EmpowerPieChart02 from "./Charts/chart-1/employees-by-gender";
import EmpowerPieChart03 from "./Charts/chart-3/chart-3";
import EmpowerBarGroupChart from "./Charts/chart-4/chart-4";
import EmpowerBarChart01 from "./Charts/chart-2/employees-by-gender";
import EmpowerBarChart02 from "./Charts/chart-5/chart-4";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function OtherEnvironmentalTargetsCharts() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="new-empower-chart-container-1">
      <div>
        <div className="empower-bolder-box-line">
          <div className="new-conserve-header-contents">
            <div
              className="new-conserve-header-content-left-2"
              data-aos="flip-down"
              data-aos-duration="2000"
            >
              <h1>Other Empower Targets</h1>
            </div>
          </div>
        </div>

        <div className="empower-bolder-box-line-4">
          <center className="empower-chart-topic-center">
            <div className="empower-chart-header-content-right">
              <h4 data-aos="fade-in" data-aos-duration="2000">
                <strong>Overall Employee Statistics</strong>
              </h4>
            </div>
          </center>
        </div>

        {/* <div>
            <div className="new-equality-hightlight-bolder-box-line-content">
              <div className="new-equality-hightlight-bolder-box-line-content-left">
                <div>
                  <h4
                    className="equality-highlights-header-content-right-heading"
                    data-aos="fade-in"
                    data-aos-duration="2000"
                  >
                    <strong>Target S2: Women leadership</strong>
                  </h4>
                  <br />
                  <p
                    data-aos="fade-in"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    Across the Group, we still have some way to go to achieve
                    our 30% Female leadership positions target. There has been a
                    significant increase in the Sri Lankan operations, which is
                    very encouraging, and Vietnam has maintained its percentage
                    at 33%, exceeding our target for the second year in a row.
                    <br />
                    <br />
                    In Ethiopia, due to the limited scale of operations, there
                    is only zero position that falls under the grade 7 and above
                    category, and hence the target for female leadership does
                    not apply to the country. In Bangladesh, we are making
                    conscious efforts to make strides towards our targets,
                    efforts which we believe will be reflected in the upcoming
                    reporting period.
                  </p>
                </div>
              </div>
              <div className="new-equality-hightlight-bolder-box-line-content-right">
                <EqualityHighlightsBarChart01 />
              </div>
            </div>
          </div> */}
          <div>
            <div className="new-equality-hightlight-bolder-box-line-content">
              <div className="new-equality-hightlight-bolder-box-line-content-left">
                <div>
                  <center>
                    <EmpowerPieChart01 />
                  </center>
                </div>
              </div>
              <div className="new-equality-hightlight-bolder-box-line-content-right">
                  <center>
                    <EmpowerPieChart02 />
                  </center>
              </div>
            </div>
          </div>
          
          
          <div>
            <div className="new-equality-hightlight-bolder-box-line-content">
              <div className="new-equality-hightlight-bolder-box-line-content-left">
                <div>
                  <EmpowerBarGroupChart />
                </div>
              </div>
              <div className="new-equality-hightlight-bolder-box-line-content-right">
                  
              </div>
            </div>
          </div>
          
        {/* <center>
          <div className="empower-pie-charts">
            <EmpowerPieChart01 />
            <EmpowerPieChart02 />
          </div>
          <div className="empower-bolder-box-line-2">
            <div className="empower-bar-chart">
              <EmpowerBarGroupChart />
            </div>
          </div>
        </center> */}

        {/* Chart Section 2 */}

          <div>
            <div className="new-equality-hightlight-bolder-box-line-content">
              <div className="new-equality-hightlight-bolder-box-line-content-left">
                <div>
                  <h4 data-aos="fade-in" data-aos-duration="2000">
                    <strong>Parental Leave</strong>
                  </h4>
                  <br />
                  <p
                    data-aos="fade-in"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    Over 75% of employees are returning to work after parental
                    leave, this shows improvement from last year with 100% of men
                    returning to work(compared to 96% in 2022) and 74% of women
                    (compared to 69% in 2022) returning to work.
                  </p>
                </div>
              </div>
              <div className="new-equality-hightlight-bolder-box-line-content-right">
                  <center>
                    <EmpowerPieChart03 />
                  </center>
              </div>
            </div>
          </div>

          <div>
            <div className="new-equality-hightlight-bolder-box-line-content">
              <div className="new-equality-hightlight-bolder-box-line-content-left">
                <div>
                  <center>
                    <EmpowerBarChart01 />
                  </center>
                </div>
              </div>
              <div className="new-equality-hightlight-bolder-box-line-content-right">
                  <center>
                  <EmpowerBarChart02 />
                  </center>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default OtherEnvironmentalTargetsCharts;
