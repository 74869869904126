import React from 'react'
import Page from '../templates/empower/wellbeing/spotlight/SpotLight-healthy-and-positive-relationships-program';

function spotlight() {
  return (
    <div>
      <Page/>
    </div>
  )
}

export default spotlight
