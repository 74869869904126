import React from "react";
import Page from "../templates/support/wellbing/spotlight/building-the-khang-chien-bridge";

function spotlight() {
  return (
    <div>
      <Page />
    </div>
  );
}

export default spotlight;
