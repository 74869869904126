import React from 'react'
import Page from '../templates/empower/wellbeing/spotlight/SpotLight-on-site-mental-health-clinics';

function spotlight() {
  return (
    <div>
      <Page/>
    </div>
  )
}

export default spotlight
