import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./Bangladesh.css";
import FemaleChart1 from "./bangladesh-chart-1";
import FemaleChart2 from "./srilanka-chart-2";
import FemaleChart3 from "./srilanka-chart-3";

export default function Chart() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div style={{ position: "relative" }}>
        <div className="trust-chart-01-chart-box">
          <div className="trust-section-card-container-1">
            <div className="card trust-chart-01-card">
              <center>
                <div>
                  <FemaleChart1 />
                </div>
                <div className="trust-middle-topic">
                  <div className="bangladesh-number-loader">
                    {counterOn && (
                      <CountUp start={0} end={32} duration={3} delay={0.3} />
                    )}
                    %
                  </div>
                </div>
                <br />
                <span className="trust-bangladesh_2023">2023</span>
              </center>
            </div>

            <div className="card trust-chart-01-card">
              <center>
                <div>
                  <FemaleChart2 />
                </div>
                <div className="trust-middle-topic">
                  <div className="bangladesh-number-loader">
                    {counterOn && (
                      <CountUp start={0} end={37} duration={3} delay={0.3} />
                    )}
                    %
                  </div>
                </div>
                <br />
                <span className="trust-bangladesh_2022">2022</span>
              </center>
            </div>

            <div className="card trust-chart-01-card">
              <center>
                <div>
                  <FemaleChart3 />
                </div>
                <div className="middle-topic-3">
                  <div className="bangladesh-number-loader">
                    {counterOn && (
                      <CountUp start={0} end={80} duration={3} delay={0.3} />
                    )}
                    %
                  </div>
                </div>
                <br />
                <span className="trust-bangladesh_Target">Target</span>
              </center>
            </div>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
}
