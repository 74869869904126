import React from "react";
import "./footer.css";
import { MDBFooter, MDBContainer } from "mdb-react-ui-kit";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import Logo from "../assets/logo-white.png";

export default function Footer() {
  return (
    <MDBFooter className="footer">
      <MDBContainer className="p-4 pb-0">
        <section>
          <div className="flex">
            <div className="left">
              <p className="footer-para">
                <a href="/cookie/">COOKIE POLICY</a>
                <a href="/privacy/">PRIVACY POLICY</a>
                <a href="/sitemap/">SITEMAP</a>
                {/* <a href='/chatbot'>CHATBOT / FAQ</a>             */}
              </p>
            </div>

            <div className="right right-flex">
              {/* <MDBBtn outline color="light" className='social-icon  rounded-circle' href='#!' role='button'>
                <MDBIcon fab icon='fab fa-facebook' />
              </MDBBtn>
             
              <MDBBtn outline color="light" className='social-icon rounded-circle' href='#!' role='button'>
                <MDBIcon fab icon='instagram' />
              </MDBBtn>

              <MDBBtn outline color="light" className='social-icon rounded-circle' href='#!' role='button'>
                <MDBIcon fab icon='linkedin-in' />
              </MDBBtn>

              <MDBBtn outline color="light" className='social-icon rounded-circle' href='#!' role='button'>
                <MDBIcon fab icon='youtube' />
              </MDBBtn> */}
              <div className="icons_circle-footer">
                <a
                  href="https://www.facebook.com/HirdaramaniGroup/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook size={15} />
                </a>
              </div>

              <div className="icons_circle-footer">
                <a
                  href="https://www.instagram.com/hirdaramanigroup/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={15} />
                </a>
              </div>

              <div className="icons_circle-footer">
                <a
                  href="https://www.linkedin.com/company/hirdaramani-group-of-companies/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn size={15} />
                </a>
              </div>

              <div className="icons_circle-footer">
                <a
                  href="https://www.youtube.com/channel/UCS8MIl0YX4y5hRI7JkXoUOw"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube size={15} />
                </a>
              </div>
            </div>
          </div>
        </section>
      </MDBContainer>

      <div className="footer-firm-logo">
        <a href="/">
          <img src={Logo} alt="Hirdaramani Logo" />
        </a>
      </div>

      <div
        className="bottom-footer text-center p-3"
        style={{ backgroundColor: "#313893" }}
      >
        <a className="text-white" href="http://3cs.lk">
          Web Design by 3CS &nbsp; &nbsp; | &nbsp; &nbsp;
        </a>
        Copyright © 2024 Hirdaramani Group
      </div>
    </MDBFooter>
  );
}
