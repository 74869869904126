import React, { useEffect } from "react";
import "./TargetsCharts.css";

//Charts
import TargetsBarChart01 from "./Charts/chart-1/chart-1";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function OtherEnvironmentalTargetsCharts() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      {/* <div className="childrin-education-chart-container">
        <div className="childrin-education-header-contents">
          <div
            className="childrin-education-header-content-left"
            data-aos="flip-down"
            data-aos-duration="2000"
          >
            <h1>
              Education <br />
              target
            </h1>
          </div>
          <div className="childrin-education-header-content-right">
            <p data-aos="fade-in" data-aos-duration="2000">
              <strong>Target S7: Education projects</strong>
            </p>
            <br />
            <p data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
              With a target of reaching 5,600 children through education
              programs in 2023, the Group surpassed the target considerably,
              with every country bar Ethiopia exceeding target numbers.
            </p>
            <p data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
              The importance of this program is highlighted by the fact that it
              also attracts investment from the Hirdaramani Memorial Trust
              (HMT), a separate group-level philanthropic foundation.
            </p>
          </div>
        </div>
        <br />
        <br />
        <center>
          <div className="childrin-education-bar-chart">
            <TargetsBarChart01 />
          </div>
        </center> 
      </div> */}
      <div className="new-childrin-education-chart-container-2">
        <div className="childrin-education-bolder-box-line">
          <div className="new-childrin-education-header-contents">
            <div
              className="new-conserve-header-content-left-2"
              data-aos="flip-down"
              data-aos-duration="2000"
            >
              <h1>Education target</h1>
            </div>
          </div>
        </div>
        <div>
          <div className="childrin-education-bolder-box-line-content">
            <div className="childrin-education-bolder-box-line-content-left">
              <div>
                <h4 data-aos="fade-in" data-aos-duration="2000">
                  <strong>
                    Target E17, E18, E19: Other Environmental Goals
                  </strong>
                </h4>
                <br />
                <p
                  data-aos="fade-in"
                  data-aos-duration="2000"
                  data-aos-delay="500"
                >
                  Our facilities Groupwide have achieved the HiGG vFEM target of
                  80 points. The targets for 100% Green-building certified
                  facilities and ISO certified facilities have seen continued
                  progress.
                </p>
              </div>
            </div>
            <div className="childrin-education-bolder-box-line-content-right">
              <TargetsBarChart01 />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtherEnvironmentalTargetsCharts;
