import React from "react";
import SriLanka from "./srilanka";
import "./chart-4.css";

export default function Highlights() {
  return (
    <div>
      <div>
        <h2 className="main-topic-chart-4-p">
          Work Related Injury Occurrence by Country
        </h2>

        <div className="empower-chart-4-paragraph-two-column"></div>

        <div>{<SriLanka />}</div>
      </div>
    </div>
  );
}
