import React from "react";
import Page from "../templates/support/environment/spotlight/coastal-protection-and-mangrove-projects";

export default function home() {
  return (
    <div>
      <Page />
    </div>
  );
}
