import React from 'react'
import Page from '../templates/empower/equality/spotlight/SpotLight'

function spotlight() {
  return (
    <div>
      <Page/>
    </div>
  )
}

export default spotlight
