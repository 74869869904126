import React from "react";
//import { Link } from "react-router-dom";
import Male from "./banglades-data.jsx";
import "./srilanka.css";

import img1 from "../../../../../assets/c-img-1.webp";
import img2 from "../../../../../assets/c-img-2.webp";
import img3 from "../../../../../assets/c-img-3.webp";

export default function Srilanka() {
  return (
    <div style={{ width: "100%" }}>
      <div>
        <div className="career-chart-9-srilanka-paragraph-two-column">
          <div className="career-chart-9-srilanka-paragraph-column-0"></div>
          <div className="career-chart-9-srilanka-paragraph-column-1">
            <img className="gender-button-n" src={img1} alt="Male Button" />
            <br />
            <img className="gender-button-n" src={img2} alt="Female Button" />
            <br />
            <img className="gender-button-n" src={img3} alt="Female Button" />
          </div>
          <div className="career-chart-9-srilanka-paragraph-column-2">
            <div>
              <Male />
            </div>
          </div>
          <div className="career-chart-9-srilanka-paragraph-column-3">
            <div>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div className="aa-pc">
        <div style={{ marginTop: "-20px" }}>
          <div className="career-chart-9-srilanka-paragraph-two-column">
            <div className="career-chart-9-srilanka-paragraph-column-4">
              <br />
            </div>
            <div className="career-chart-9-srilanka-paragraph-column-5"></div>
            <div className="career-chart-9-srilanka-paragraph-column-6"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
