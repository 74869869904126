import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./career-chart-5.css";
import MaleChart from "./male";
import FemaleChart from "./female";

export default function Chart() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div style={{ position: "relative" }}>
        <h2 className="career-employees-chart-topic-5">
          Number of people trained
        </h2>
        <br />

        <div style={{ width: "80%", padding:'0 0 50px 0' }}>
          <div className="career-card-container-5">
            <div className="card" style={{ width: "81px" }}>
              <center>
                <div>
                  <MaleChart />
                </div>
                <br />
                <span className="career-para-5-male">Male</span>
                <div className="career-number-loader-5-male">
                  {counterOn && (
                    <CountUp start={0} end={19308} duration={3} delay={0.3} />
                  )}
                </div>
              </center>
            </div>

            <div className="card" style={{ width: "81px" }}>
              <center>
                <div>
                  <FemaleChart />
                </div>
                <br />
                <span className="career-para-5-female">Female</span>
                <div className="career-number-loader-5-female">
                  {counterOn && (
                    <CountUp start={0} end={37152} duration={3} delay={0.3} />
                  )}
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
}
