import React from "react";
import Page from "../templates/trust/trustpage";

export default function home() {
  return (
    <div>
      <Page />
    </div>
  );
}
