import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./Sri-Lanka.css";
import FemaleChart1 from "./image-chart-1";
import FemaleChart2 from "./image-chart-0-ii";

export default function Chart() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div style={{ position: "relative" }}>
        <div className="support-children-chart-box">
          <div className="trust-card-container-1">
            <div className="card" style={{ width: "250px" }}>
              <center>
                <div>
                  <FemaleChart1 />
                </div>
                <br />

                <div className="sri_lanka-number-loader">
                  <span className="support-children-Sri_Lanka_2023">
                    <p className="num-c support-children-chart-01">
                      Actual
                      <br />
                      {counterOn && (
                        <CountUp start={0} end={480} duration={3} delay={0.3} />
                      )}
                    </p>
                  </span>
                </div>
              </center>
            </div>

            <div className="card" style={{ width: "250px" }}>
              <center>
                <div>
                  <FemaleChart2 />
                </div>
                <br />
                <div className="sri_lanka-number-loader">
                  <span className="support-children-Sri_Lanka_2022">
                    <p className="num-c support-children-chart-01">
                      Target
                      <br />
                      {counterOn && (
                        <CountUp start={0} end={0} duration={3} delay={0.3} />
                      )}
                    </p>
                  </span>
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
}
