import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./srilanka.css";

export default function Male() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <br />
      <div className="sri-lanka-box-1-c">
        {counterOn && <CountUp start={0} end={0} duration={3} delay={0.3} />}
      </div>
      <br />
      <div className="sri-lanka-box-2-c">
        {counterOn && <CountUp start={0} end={0} duration={3} delay={0.3} />}
      </div>
      <br />
      <div className="sri-lanka-box-3-c">
        {counterOn && <CountUp start={0} end={138} duration={3} delay={0.3} />}
      </div>
      <br />
    </ScrollTrigger>
  );
}
