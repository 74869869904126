import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./Sri-Lanka.css";
import FemaleChart1 from "./image-chart-0-i";
import FemaleChart2 from "./image-chart-4";

export default function Chart() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div style={{ position: "relative" }}>
        <div className="chart-box  support-wellbeing-chart-01-chart-box">
          <div className="trust-card-container-1">
            <div className="card support-wellbeing-chart-01-card">
              <center>
                <div>
                  <FemaleChart1 />
                </div>
                <br />

                <div className="sri_lanka-number-loader">
                  <span className="support-welbeing-Sri_Lanka_2023">
                    <p className="num-c">
                      Actual
                      <br />
                      {counterOn && (
                        <CountUp
                          start={0}
                          end={0}
                          duration={3}
                          delay={0.3}
                        />
                      )}
                    </p>
                  </span>
                </div>
              </center>
            </div>

            <div className="card support-wellbeing-chart-01-card">
              <center>
                <div>
                  <FemaleChart2 />
                </div>
                <br />
                <div className="sri_lanka-number-loader">
                  <span className="support-welbeing-Sri_Lanka_2022">
                    <p className="num-c">
                      Target
                      <br />
                      {counterOn && (
                        <CountUp
                          start={1000}
                          end={1400}
                          duration={3}
                          delay={0.3}
                        />
                      )}
                    </p>
                  </span>
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
}
