import React from 'react'
import HomePage from "../templates/home/homepage";


export default function home() {
  return (
    <div>
        <HomePage />
    </div>
  )
}
