import React from "react";
import Page from "../templates/support/wellbing/spotlight/support-for-hospitals";

function spotlight() {
  return (
    <div>
      <Page />
    </div>
  );
}

export default spotlight;
