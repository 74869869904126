import React from "react";
import Page from "../templates/support/environment/environment";

export default function home() {
  return (
    <div>
      <Page />
    </div>
  );
}
