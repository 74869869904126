import React from 'react'
import Page from '../templates/empower/equality/equalitypage'

function equality() {
  return (
    <div>
      <Page/>
    </div>
  )
}

export default equality
