import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import './chart.css';
import MaleChart from "./middle_manager_male";
import FemaleChart2 from "./middle_manager_female";

export default function Chart() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div style={{ position: "relative" }}>
        <div className="chart-box-ban">
          <div className="career-card-container-5">
            <div className="card" style={{ width: "120px" }}>
              <center>
                <div>
                  <MaleChart />
                </div>
                <div className="middle-topic">
                  <div className="equlity-chart-1-sri_lanka-number-loader">
                    {counterOn && (
                      <CountUp start={0} end={77} duration={3} delay={0.3} />
                    )}
                    %
                  </div>
                </div>
              </center>
            </div>

            <div className="card" style={{ width: "120px" }}>
              <center>
                <div>
                  <FemaleChart2 />
                </div>
                <div className="middle-topic">
                  <div className="equlity-chart-1-sri_lanka-number-loader">
                    {counterOn && (
                      <CountUp start={0} end={23} duration={3} delay={0.3} />
                    )}
                    %
                  </div>
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
}
